import request from '@/utils/request'

export function fetchUserData() {
    return request.get('https://api.github.com/users/woai3c')
}

export const uploadFile = (params) => request.post('admin/upload_image', params)
export const userLogin = (params) => request.post('admin/user/login', params)

export const getFruitOrderList = (params) => request.post('admin/fruit_order/admin_fruit_order_list', params)
export const refundOrder = (params) => request.post('admin/fruit_order/admin_update_order_status_refund', params)
export const updatedShippingInfo = (params) => request.post('admin/fruit_order/admin_update_shipping_info', params)

export const getPlotOrderList = (params) => request.post('admin/fruit_order/admin_plot_order_list', params)

export const createFruit = (params) => request.post('admin/fruitSale/create', params)
export const updateFruit = (params) => request.post('admin/backend_manager/fruit_sale/update_fruit_sale', params)
export const getFruitList = (params) => request.post('admin/backend_manager/fruit_sale/list_fruit_sale', params)
export const deleteFruitById = (params) => request.post('admin/backend_manager/fruit_sale/destory_by_id', params)

export const getPlotList = (params) => request.post('admin/backend_manager/orchard_plot/list_orchard_plot', params)
export const updatePlot = (params) => request.post('admin/backend_manager/orchard_plot/update_orchard_plot', params)
export const createPlot = (params) => request.post('admin/orchardPlot/create', params)
export const deletePlotById = (params) => request.post('admin/backend_manager/orchard_plot/destory_by_id', params)


export const getHomePageSliderList = (params) => request.post('admin/backend_manager/config/home_page_slider_list', params)
export const deleteHomePageSliderById = (params) => request.post('admin/backend_manager/config/home_page_slider_destory_by_id', params)
export const createHomePageSlider = (params) => request.post('admin/config/home_page_slider_create', params)
export const updateHomePageSlider = (params) => request.post('admin/backend_manager/config/home_page_slider_update', params)

export const getTreeTypeList = (params) => request.post('admin/backend_manager/treeType/admin_list', params)
export const updateTreeType = (params) => request.post('admin/backend_manager/treeType/update', params)
export const createTreeType = (params) => request.post('admin/backend_manager/treeType/create', params)
export const deleteTreeTypeById = (params) => request.post('admin/backend_manager/treeType/deleteById', params)


export const getPlotTreeList = (params) => request.post('admin/backend_manager/orchardPlotNumber/admin_list', params)
export const deletePlotTreeById = (params) => request.post('admin/backend_manager/orchardPlotNumber/deleteById', params)
export const initializeNumbers = (params) => request.post('admin/orchardPlotNumber/initializeNumbers', params)
